import * as React from "react";
import { inject } from "mobx-react";
import { LocationFieldProps, TypeaheadProps, EssConfig } from "./typings";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { LocationState } from "src/stores/wizard/config/staticConfigs/global/typings";
import { TypeaheadFallbackInput, useLazyTypeahead } from "utils/hooks/useLazyTypeahead";
import { isVariantEnabled } from "../../utility/experiment";
import { Experiment as Experiments, ExperimentControl, ExperimentVariant } from "@shared-ui/experiment-context";
import { HotelRatesType } from "stores/wizard/state/typings";

export const LocationField: React.FC<LocationFieldProps> = (props) => (
  <Experiments name="PWA_Packages_Exclude_MultiRegion_Destination_Search">
    <ExperimentControl>
      <WrappedLocationField {...props} multiRegionPackageExperimentEnabled={false} />
    </ExperimentControl>
    <ExperimentVariant bucket={1}>
      <WrappedLocationField {...props} multiRegionPackageExperimentEnabled />
    </ExperimentVariant>
  </Experiments>
);

const WrappedLocationField: React.FC<LocationFieldProps & { multiRegionPackageExperimentEnabled: boolean }> = inject(
  "analytics"
)(
  ({
    config,
    context,
    essAdapterConfig,
    icon,
    invalid,
    label,
    location,
    name,
    subLob,
    onUpdate,
    setInputsRefs,
    stid,
    typeaheadEmptyResultPlaceholder,
    typeaheadPlaceholder,
    value,
    globalConfig,
    multiFlight,
    shouldHideHiddenInputs,
    multiRegionPackageExperimentEnabled,
  }) => {
    const { typeaheadModule } = useLazyTypeahead();

    if (!context) {
      // Check for injected stores
      return null;
    }

    const userLocation =
      !multiFlight && globalConfig && globalConfig.originType === LocationState.UNBIASED && globalConfig.userLocation
        ? globalConfig.userLocation
        : "";
    const regionIdParam = config.queryParams?.regionId ?? "";
    const iataCodeParam = config.queryParams?.iataCode ?? "";
    const latLongParam = config.queryParams?.latLong ?? "";
    const countryCodeParam = config.queryParams?.countryCode ?? "";
    if (userLocation && !location.value) location.value = userLocation;
    let essAdapterFeatures = essAdapterConfig.features;
    if (essAdapterFeatures.indexOf("consistent_display") < 0) {
      essAdapterFeatures += "|consistent_display";
    }

    const onClear = () => {
      location.value = "";
    };

    if (!typeaheadModule) {
      return <TypeaheadFallbackInput label={label} value={value} />;
    }

    const { ESSAdapter, UitkTypeahead } = typeaheadModule;

    let regionType = essAdapterConfig.regionType;
    let allowExactMatch = true;
    if (
      isVariantEnabled(
        context as ExtendedContextStore,
        "Remove_POI_and_Neighborhood_from_flight_and_package_typeahead",
        1
      )
    ) {
      if ((essAdapterConfig.lob === "PACKAGES" && !essAdapterConfig.dest) || essAdapterConfig.lob === "FLIGHTS") {
        regionType = 4935;
      }
    }

    if (essAdapterConfig.lob === "PACKAGES" && essAdapterConfig.dest && multiRegionPackageExperimentEnabled) {
      regionType = 735;
    }

    if (essAdapterConfig.lob === "HOTELS" && subLob === HotelRatesType.NEW_PROPERTY_SEARCH) {
      allowExactMatch = false;
    }

    const adapter: EssConfig = {
      client: essAdapterConfig.client,
      dest: essAdapterConfig.dest,
      features: essAdapterFeatures,
      guid: context.guid,
      inputId: stid,
      lob: essAdapterConfig.lob,
      locale: context.locale,
      maxResults: essAdapterConfig.maxResults,
      regionType,
      siteId: context.site.id,
      regionId: essAdapterConfig.regionId,
      domain: "/",
      personalize: true,
      alternate: essAdapterConfig.alternate,
      trending: essAdapterConfig.trending,
      packageType: essAdapterConfig.packageType,
      subLob,
      latLong: essAdapterConfig.latLong,
      showCurrentLocation: config.typeaheadCurrentLocationEnabled,
      historyDetail: essAdapterConfig.historyDetail,
    };

    const typeaheadProps: TypeaheadProps = {
      onMountCB: setInputsRefs,
      onUpdate,
      hasClearButton: false,
      handleClear: onClear,
      allowExactMatch,
      icon,
      name,
      label,
      invalid,
      value,
      stid,
      typeaheadPlaceholder,
      typeaheadEmptyResultPlaceholder,
    };

    return (
      <div data-testid={`${stid}-container`}>
        <Experiments name="EGTA_throttle_and_debounce">
          <ExperimentControl>
            <UitkTypeahead
              adapter={new ESSAdapter({ ...adapter, debounceVariant: "42716.0" })}
              {...typeaheadProps}
              debounceRate={0}
            />
          </ExperimentControl>
          <ExperimentVariant bucket={1}>
            <UitkTypeahead
              adapter={new ESSAdapter({ ...adapter, debounceVariant: "42716.1" })}
              {...typeaheadProps}
              debounceRate={200}
            />
          </ExperimentVariant>
          <ExperimentVariant bucket={2}>
            <UitkTypeahead
              adapter={new ESSAdapter({ ...adapter, debounceVariant: "42716.2" })}
              {...typeaheadProps}
              debounceRate={300}
            />
          </ExperimentVariant>
          <ExperimentVariant bucket={3}>
            <UitkTypeahead
              adapter={new ESSAdapter({ ...adapter, debounceVariant: "42716.3" })}
              {...typeaheadProps}
              debounceRate={400}
            />
          </ExperimentVariant>
        </Experiments>

        {!shouldHideHiddenInputs && regionIdParam && location.metaData.regionId && (
          <input type="hidden" name={regionIdParam} value={location.metaData.regionId} />
        )}

        {!shouldHideHiddenInputs && latLongParam && location.metaData.latLong && (
          <input type="hidden" name={latLongParam} value={location.metaData.latLong} />
        )}

        {iataCodeParam && location.metaData.ttla && (
          <input type="hidden" name={iataCodeParam} value={location.metaData.ttla} />
        )}

        {countryCodeParam && location.metaData.countryCode && (
          <input type="hidden" name={countryCodeParam} value={location.metaData.countryCode} />
        )}
        {config.typeaheadCurrentLocationEnabled &&
          config.currentLocationInputName &&
          location.metaData &&
          location.metaData.isCurrentLocation &&
          location.metaData.latLong && (
            <input type="hidden" name={config.currentLocationInputName} value={location.metaData.latLong} />
          )}
      </div>
    );
  }
);

export default LocationField;
